const GATEWAY_ENDPOINT = "https://api.biens.ai";
// const GATEWAY_ENDPOINT = "http://127.0.0.1:3000";

export const LOGIN_ENDPOINT = `${GATEWAY_ENDPOINT}/login`;
export const DASHBOARD_COMPANY_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/company`;
export const CUSTOMERS_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/customers`;
export const GET_MESSAGES_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/messages`;
export const GET_PREVIOUS_MESSAGES_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/previous-messages`;
export const GET_TEAM_MEMBERS_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/team-members`;

export const UPLOAD_COMPANY_PHOTO_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/upload-company-photo`;
export const UPLOAD_USER_PHOTO_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/member-photo`;
export const PUT_COMPANY_NAME_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/company-name`;
export const PUT_MEMBER_NAME_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/member-name`;
export const PUT_WELCOME_MESSAGE_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/welcome-message`;
export const PUT_CHAT_CONFIG_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/chat-config`;
export const CHANGE_PASSWORD_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/change-password`;
export const SET_TELEGRAM_BOT_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/set-telegram-bot`;
export const REMOVE_TELEGRAM_BOT_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/remove-telegram-bot`;
export const REMOVE_TEAM_MEMBER_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/remove-team-member`;
export const ADD_TEAM_MEMBER_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/add-team-member`;
export const PUT_TEAM_MEMBER_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/team-member`;
export const PUT_AUTO_ASSIGN_BIENS_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/auto-assign-biens`;
export const AI_RULE_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/ai-rule`;
export const CUSTOMER_ASSIGN_TO_AGENT_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/assign-to-agent`;
export const SEND_MESSAGE_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/send-message`;
export const PUT_MESSAGE_STATUS_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/message-status`;
export const CONVERSATION_ENDPOINT = `${GATEWAY_ENDPOINT}/dashboard/conversation`;
