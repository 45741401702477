import Dialog from "./index";
import React, { useRef, useState } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import ProfilePhoto from "../ProfilePhoto";
import { Clear } from "@mui/icons-material";
import { REQUEST_CUSTOMER_ASSIGN_TO_AGENT } from "../../api/requests";
import Button from "../Button";
import { toast } from "react-toastify";
import { assignToAssistant } from "../../store/slices/conversationsSlice";
import { useTranslation } from "react-i18next";

type Props = {
  customerId: string;
  closeDialog: () => void;
};
const AssignToAgent: React.FC<Props> = ({ customerId, closeDialog }) => {
  const { t } = useTranslation();
  const { team } = useSelector((state: RootState) => state.team);
  const dispatch = useDispatch();
  const dialogRef = useRef<HTMLDivElement>(null);
  const [loadingId, setLoadingId] = useState("");
  const handleCloseWithAnimation = () => {
    dialogRef.current?.classList.add("animate-scale-down-anim");
    setTimeout(() => {
      closeDialog();
    }, 200);
  };
  useOutsideClick(dialogRef, handleCloseWithAnimation);

  const handleAssignToAgent = (agentId: string) => {
    setLoadingId(agentId);
    REQUEST_CUSTOMER_ASSIGN_TO_AGENT({ customerId, agentId })
      .then(() => {
        dispatch(assignToAssistant({ customerId, agentId }));
        handleCloseWithAnimation();
      })
      .catch(() => toast.error("Error happen when assign conversation"))
      .finally(() => {
        setLoadingId("");
      });
  };

  return (
    <Dialog>
      <div
        ref={dialogRef}
        className="bg-white rounded-lg px-4 py-2 min-w-[25vw] animate-scale-anim"
      >
        <div className="flex items-center justify-between border-b-2 pb-2 mb-2">
          <h3 className="font-bold">{t("select_member")}</h3>
          <button
            className="hover:bg-gray-200 rounded-lg"
            onClick={handleCloseWithAnimation}
          >
            <Clear className="text-gray-700" />
          </button>
        </div>
        {team.map((member) => (
          <Button
            key={member._id}
            type="button"
            size="sm"
            variant="outlined"
            className="w-full mt-2"
            onClick={() => handleAssignToAgent(member._id)}
            isLoading={loadingId === member._id}
            title={
              <>
                <ProfilePhoto
                  name={member.name}
                  photoUrl={member.photoUrl}
                  size={36}
                />
                <h6>{member.name}</h6>
              </>
            }
          />
        ))}
      </div>
    </Dialog>
  );
};

export default AssignToAgent;
