import ChangePasswordDialog from "../../components/Dialog/ChangePassword";
import { useState } from "react";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import UploadPhotoDialog from "../../components/Dialog/UploadPhoto";
import ProfilePhoto from "../../components/ProfilePhoto";
import { REQUEST_PUT_MEMBER_NAME } from "../../api/requests";
import { toast } from "react-toastify";
import { setUserName } from "../../store/slices/authSlice";
import { useTranslation } from "react-i18next";

const UserSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { company } = useSelector((state: RootState) => state.auth);
  const [name, setName] = useState(company.userName);
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);
  const [uploadProfilePhotoDialog, setUploadProfilePhotoDialog] =
    useState(false);
  const [isUserNameUpdating, setIsUserNameUpdating] = useState(false);

  const handleUpdateUserName = () => {
    setIsUserNameUpdating(true);
    REQUEST_PUT_MEMBER_NAME(name)
      .then(() => {
        toast.success("Name updated");
        dispatch(setUserName(name));
      })
      .catch(() => {
        toast.error("Server error happened");
      })
      .finally(() => setIsUserNameUpdating(false));
  };

  return (
    <div className="container mx-auto">
      {changePasswordDialog ? (
        <ChangePasswordDialog
          closeDialog={() => setChangePasswordDialog(false)}
        />
      ) : null}
      {uploadProfilePhotoDialog ? (
        <UploadPhotoDialog
          closeDialog={() => setUploadProfilePhotoDialog(false)}
          type="user"
        />
      ) : null}
      <div className="bg-white p-2 border-2 rounded-lg mb-2 flex flex-col items-center">
        <h3 className="font-bold text-sm text-gray-700 self-start">
          {t("user_image")}
        </h3>
        <ProfilePhoto
          name={company.userName}
          photoUrl={company.userPhotoUrl}
          size={96}
        />
        <Button
          title={t("change_picture")}
          type="button"
          className="w-3/5 mt-3 justify-center"
          size="sm"
          onClick={() => setUploadProfilePhotoDialog(true)}
        />
      </div>
      <div className="bg-white p-2 border-2 rounded-lg mb-2">
        <h3 className="font-bold text-sm text-gray-700">{t("name")}</h3>
        <span className="text-gray-500 text-xs">{t("your_user_name")}</span>
        <input
          type="text"
          className="w-full py-1 px-2 rounded-lg border-2 mt-1"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Button
          title={t("update")}
          type="button"
          className="mt-3"
          size="sm"
          onClick={handleUpdateUserName}
          isLoading={isUserNameUpdating}
        />
      </div>
      <div className="bg-white p-2 border-2 rounded-lg">
        <h3 className="font-bold text-sm text-gray-700">{t("security")}</h3>
        <Button
          title={t("change_password")}
          type="button"
          className="mt-3"
          size="sm"
          onClick={() => setChangePasswordDialog(true)}
        />
      </div>
    </div>
  );
};

export default UserSettings;
