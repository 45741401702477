import {
  Instagram,
  Language,
  Facebook,
  WhatsApp,
  Telegram,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Company } from "../../store/slices/authSlice";
import IntegrationBox from "../../components/IntegrationBox";
import { useTranslation } from "react-i18next";

export type Integration = {
  name: string;
  key: keyof Company;
  link: string;
  icon: JSX.Element;
  description: string;
};

const Integrations = () => {
  const { t } = useTranslation();
  const { company } = useSelector((state: RootState) => state.auth);
  const IntegrationOptions: Integration[] = [
    {
      name: t("web"),
      key: "isWeb",
      link: "/dashboard/integrations/web",
      icon: (
        <Language
          sx={{
            fontSize: 48,
            color: "#00a2e8",
          }}
        />
      ),
      description: t("web_desc"),
    },
    {
      name: "Whatsapp",
      key: "isWhatsapp",
      link: "/dashboard/integrations/whatsapp",
      icon: (
        <WhatsApp
          sx={{
            fontSize: 48,
            color: "#25D366",
          }}
        />
      ),
      description: t("whatsapp_desc"),
    },
    {
      name: t("instagram"),
      key: "isInstagram",
      link: "/dashboard/integrations/instagram",
      icon: (
        <Instagram
          sx={{
            fontSize: 48,
            color: "#DD2A7B",
          }}
        />
      ),
      description: t("instagram_desc"),
    },
    {
      name: t("messenger"),
      key: "isMessenger",
      link: "/dashboard/integrations/messenger",
      icon: (
        <Facebook
          sx={{
            fontSize: 48,
            color: "#1877F2",
          }}
        />
      ),
      description: t("messenger_desc"),
    },
    {
      name: t("telegram"),
      key: "isTelegram",
      link: "/dashboard/integrations/telegram",
      icon: (
        <Telegram
          sx={{
            fontSize: 48,
            color: "#24A1DE",
          }}
        />
      ),
      description: t("telegram_desc"),
    },
  ];
  return (
    <div className="lg:container lg:mx-auto px-3 py-4 pt-[calc(50px+1rem)] lg:pt-4">
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
        {IntegrationOptions.map((integration, i) => (
          <IntegrationBox
            key={i}
            integration={integration}
            isCompleted={!!company[integration.key]}
          />
        ))}
      </div>
    </div>
  );
};

export default Integrations;
