import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Button from "../../components/Button";
import { useState } from "react";
import { toast } from "react-toastify";
import ChangePasswordDialog from "../../components/Dialog/ChangePassword";
import UploadPhotoDialog from "../../components/Dialog/UploadPhoto";
import {
  REQUEST_PUT_COMPANY_NAME,
  REQUEST_PUT_WELCOME_MESSAGE,
} from "../../api/requests";
import {
  setCompanyName,
  setCompanyWelcomeMessage,
} from "../../store/slices/authSlice";
import ProfilePhoto from "../../components/ProfilePhoto";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { company } = useSelector((state: RootState) => state.auth);
  const [name, setName] = useState(company.companyName);
  const [welcomeMessage, setWelcomeMessage] = useState(company.welcomeMessage);
  const [isCompanyNameUpdating, setIsCompanyNameUpdating] = useState(false);
  const [isWelcomeMessageUpdating, setIsWelcomeMessageUpdating] =
    useState(false);
  const [uploadProfilePhotoDialog, setUploadProfilePhotoDialog] =
    useState(false);

  const handleUpdateCompanyName = () => {
    setIsCompanyNameUpdating(true);
    REQUEST_PUT_COMPANY_NAME(name)
      .then(() => {
        toast.success("Name updated");
        dispatch(setCompanyName(name));
      })
      .catch(() => {
        toast.error("Server error happened");
      })
      .finally(() => setIsCompanyNameUpdating(false));
  };

  const handleUpdateWelcomeMessage = () => {
    setIsWelcomeMessageUpdating(true);
    REQUEST_PUT_WELCOME_MESSAGE(welcomeMessage)
      .then(() => {
        dispatch(setCompanyWelcomeMessage(welcomeMessage));
        toast.success("Welcome message updated");
      })
      .catch(() => {
        toast.error("Server error happened");
      })
      .finally(() => setIsWelcomeMessageUpdating(false));
  };

  return (
    <div className="container mx-auto">
      {uploadProfilePhotoDialog ? (
        <UploadPhotoDialog
          closeDialog={() => setUploadProfilePhotoDialog(false)}
          type="company"
        />
      ) : null}
      <div className="bg-white p-2 border-2 rounded-lg mb-2 flex flex-col items-center">
        <h3 className="font-bold text-sm text-gray-700 self-start">
          {t("chat_image")}
        </h3>
        <ProfilePhoto
          name={company.companyName}
          photoUrl={company.companyPhotoUrl}
          size={96}
        />
        <Button
          title={t("change_picture")}
          type="button"
          className="w-3/5 mt-3 justify-center"
          size="sm"
          onClick={() => setUploadProfilePhotoDialog(true)}
        />
      </div>
      <div className="bg-white p-2 border-2 rounded-lg mb-2">
        <h3 className="font-bold text-sm text-gray-700">{t("company_name")}</h3>
        <span className="text-gray-500 text-xs">{t("company_name_desc")}</span>
        <input
          type="text"
          className="w-full py-1 px-2 rounded-lg border-2 mt-1"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Button
          title={t("update")}
          type="button"
          className="mt-3"
          size="sm"
          onClick={handleUpdateCompanyName}
          isLoading={isCompanyNameUpdating}
        />
      </div>
      <div className="bg-white p-2 border-2 rounded-lg mb-2">
        <h3 className="font-bold text-sm text-gray-700">
          {t("welcome_message")}
        </h3>
        <span className="text-gray-500 text-xs">
          {t("welcome_message_desc")}
        </span>
        <textarea
          className="w-full py-1 px-2 rounded-lg border-2 mt-1 min-h-[40px] max-h-[100px]"
          value={welcomeMessage}
          onChange={(e) => setWelcomeMessage(e.target.value)}
        />
        <Button
          title={t("update")}
          type="button"
          className="mt-3"
          size="sm"
          onClick={handleUpdateWelcomeMessage}
          isLoading={isWelcomeMessageUpdating}
        />
      </div>
    </div>
  );
};

export default Settings;
