import React, { useState } from "react";
import ColorPicker from "../../components/ColorPicker";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  ExpandMore,
  Splitscreen,
  Input,
  Forum,
  ControlCamera,
  QuestionMark,
} from "@mui/icons-material";
import Button from "../../components/Button";
import MockChat from "./MockChat";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Company, setCompanyChatConfig } from "../../store/slices/authSlice";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";
import MockIcon from "./MockIcon";
import ResetChatConfigDialog from "../../components/Dialog/ResetChatConfig";
import { DEFAULT_CHAT_CONFIG } from "../../store/defaults";
import { REQUEST_PUT_CHAT_CONFIG } from "../../api/requests";
import SliderInput from "../../components/SliderInput";
import { useTranslation } from "react-i18next";

type AccordionItem = {
  title: string;
  tooltip?: string;
  type: string;
  value: keyof Company["chatConfig"];
  onFocus?: () => void;
  onBlur?: () => void;
  maxLength?: number;
};
type ChatConfigItem = {
  key: "layout" | "input" | "messages";
  accordionName: string;
  accordionId: string;
  icon: JSX.Element;
  items: AccordionItem[];
};
type ChatConfigs = ChatConfigItem[];
const ChatConfig = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { company } = useSelector((state: RootState) => state.auth);
  const [chatConfig, setChatConfig] = useState(company.chatConfig);
  const [expanded, setExpanded] = useState<
    "layout" | "input" | "messages" | "position"
  >();
  const [onInputFocus, setOnInputFocus] = useState(false);
  const [onDisabledInputFocus, setOnDisabledInputFocus] = useState(false);
  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);
  const [isConfigUpdating, setIsConfigUpdating] = useState(false);

  const CHAT_CONFIG: ChatConfigs = [
    {
      key: "layout",
      accordionName: t("appearance_layout"),
      accordionId: "layout-accordion",
      icon: <Splitscreen className="text-gray-600 me-1" />,
      items: [
        {
          title: t("appearance_hbg"),
          type: "colorpicker",
          value: "hBg",
        },
        {
          title: t("appearance_cbg"),
          type: "colorpicker",
          value: "cBg",
        },
        {
          title: t("appearance_astc"),
          type: "colorpicker",
          value: "asTc",
        },
        {
          title: t("appearance_ostc"),
          type: "colorpicker",
          value: "osTc",
        },
      ],
    },
    {
      key: "messages",
      accordionName: t("appearance_messages"),
      accordionId: "messages-accordion",
      icon: <Forum className="text-gray-600 me-1" />,
      items: [
        {
          title: t("appearance_rmbg"),
          type: "colorpicker",
          value: "rmBg",
        },
        {
          title: t("appearance_rmtc"),
          type: "colorpicker",
          value: "rmTc",
        },
        {
          title: t("appearance_rmdc"),
          type: "colorpicker",
          value: "rmDc",
        },
        {
          title: t("appearance_smbg"),
          type: "colorpicker",
          value: "smBg",
        },
        {
          title: t("appearance_smtc"),
          type: "colorpicker",
          value: "smTc",
        },
        {
          title: t("appearance_smdc"),
          type: "colorpicker",
          value: "smDc",
        },
      ],
    },
    {
      key: "input",
      accordionName: t("appearance_input"),
      accordionId: "input-accordion",
      icon: <Input className="text-gray-600 me-1" />,
      items: [
        {
          title: t("appearance_ibg"),
          type: "colorpicker",
          value: "iBg",
        },
        {
          title: t("appearance_asic"),
          type: "colorpicker",
          value: "aSIc",
          onFocus: () => setOnInputFocus(true),
          onBlur: () => setOnInputFocus(false),
        },
        {
          title: t("appearance_itc"),
          type: "colorpicker",
          value: "iTc",
          onFocus: () => setOnInputFocus(true),
          onBlur: () => setOnInputFocus(false),
        },
        {
          title: t("appearance_it"),
          tooltip: t("appearance_it_tooltip"),
          type: "input",
          value: "iT",
          maxLength: 40,
        },
      ],
    },
  ];

  const changeConfigVal = (key: keyof Company["chatConfig"], val: string) => {
    const tmpChatConfig = { ...chatConfig };
    // @ts-ignore
    tmpChatConfig[key] = val;
    setChatConfig(tmpChatConfig);
  };

  const handleUpdateConfig = () => {
    setIsConfigUpdating(true);
    REQUEST_PUT_CHAT_CONFIG(chatConfig)
      .then(() => {
        dispatch(setCompanyChatConfig(chatConfig));
        toast.success(t("chat_config_updated"));
      })
      .catch(() => toast.error(t("chat_config_update_error")))
      .finally(() => setIsConfigUpdating(false));
  };

  return (
    <div className="container mx-auto h-full lg:px-3 lg:py-4">
      {isResetDialogOpen ? (
        <ResetChatConfigDialog
          setChatConfig={setChatConfig}
          closeDialog={() => setIsResetDialogOpen(false)}
        />
      ) : null}
      <div className="flex w-full h-dvh lg:h-full">
        <div className="hidden lg:flex w-[30%] h-full shadow rounded-tl-lg rounded-bl-lg bg-white flex-col">
          <div className="p-2 mb-2 h-full overflow-y-auto">
            {CHAT_CONFIG.map((config) => (
              <Accordion
                key={config.key}
                elevation={0}
                disableGutters={true}
                sx={{
                  "&:before": {
                    display: "none",
                  },
                  "& > div": {
                    padding: 0,
                  },
                }}
                expanded={expanded === config.key}
                onChange={(e, expanded) =>
                  setExpanded(expanded ? config.key : undefined)
                }
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls={config.accordionId}
                  id={config.accordionId}
                >
                  <div className="flex items-center">
                    {config.icon}
                    {config.accordionName}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {config.items.map((item, i) => (
                    <>
                      <div className="flex items-center">
                        <h3 className="font-bold text-sm text-gray-700 my-2">
                          {item.title}
                        </h3>
                        {item.tooltip ? (
                          <Tooltip title={item.tooltip}>
                            <QuestionMark
                              sx={{ fontSize: 14 }}
                              className="text-biens ms-2"
                            />
                          </Tooltip>
                        ) : null}
                      </div>

                      {item.type === "colorpicker" ? (
                        <ColorPicker
                          color={chatConfig[item.value] as string}
                          setColor={(newVal) =>
                            changeConfigVal(item.value, newVal)
                          }
                          onFocus={item.onFocus || undefined}
                          onBlur={item.onBlur || undefined}
                        />
                      ) : item.type === "input" ? (
                        <input
                          type="text"
                          className="border-2 rounded p-1 flex items-center relative w-full text-sm outline-0"
                          value={chatConfig[item.value]}
                          onChange={(e) =>
                            changeConfigVal(item.value, e.target.value)
                          }
                          maxLength={item.maxLength}
                        />
                      ) : null}
                    </>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}

            <Accordion
              elevation={0}
              disableGutters={true}
              expanded={expanded === "position"}
              onChange={(e, expanded) =>
                setExpanded(expanded ? "position" : undefined)
              }
              sx={{
                "&:before": {
                  display: "none",
                },
                "& > div": {
                  padding: 0,
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="position-accordion"
                id="position-accordion"
              >
                <div className="flex items-center">
                  <ControlCamera className="text-gray-600 me-1" />
                  {t("appearance_position")}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <h3 className="font-bold text-sm text-gray-700 my-2">
                  {t("appearance_dp")}
                </h3>
                <div className="flex items-center justify-between">
                  <Button
                    title={t("appearance_dp_l")}
                    type="button"
                    size="sm"
                    variant={chatConfig.dP === "l" ? "filled" : "outlined"}
                    className="w-full"
                    onClick={() => changeConfigVal("dP", "l")}
                  />
                  <Button
                    title={t("appearance_dp_r")}
                    type="button"
                    size="sm"
                    variant={chatConfig.dP === "r" ? "filled" : "outlined"}
                    className="w-full ms-2"
                    onClick={() => changeConfigVal("dP", "r")}
                  />
                </div>
                <div className="rounded flex items-center justify-between my-2 py-1">
                  <h3 className="font-bold text-sm text-gray-700">
                    {t("appearance_vm")}
                  </h3>
                  <div className="w-[150px]">
                    <SliderInput
                      min={0}
                      max={99}
                      value={chatConfig.vM || DEFAULT_CHAT_CONFIG.vM}
                      setValue={(e) => changeConfigVal("vM", String(e))}
                      step={1}
                    />
                  </div>
                </div>
                <div className="rounded flex items-center justify-between my-2 py-1">
                  <h3 className="font-bold text-sm text-gray-700">
                    {t("appearance_hm")}
                  </h3>
                  <div className="w-[150px]">
                    <SliderInput
                      min={0}
                      max={99}
                      value={chatConfig.hM || DEFAULT_CHAT_CONFIG.hM}
                      setValue={(e) => changeConfigVal("hM", String(e))}
                      step={1}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="flex items-center p-2 justify-between border-t-2">
            <Button
              title={t("reset")}
              type="button"
              size="sm"
              variant="outlined"
              onClick={() => setIsResetDialogOpen(true)}
            />
            <Button
              title={t("save")}
              type="button"
              size="sm"
              onClick={handleUpdateConfig}
              isLoading={isConfigUpdating}
            />
          </div>
        </div>
        <div className="bg-gray-100 w-screen lg:w-[70%] border-l-2 shadow rounded-tr-lg rounded-br-lg p-2 flex items-center justify-center">
          {expanded === "position" ? (
            <MockIcon
              vM={chatConfig.vM || DEFAULT_CHAT_CONFIG.vM}
              hM={chatConfig.hM || DEFAULT_CHAT_CONFIG.hM}
              dP={chatConfig.dP || DEFAULT_CHAT_CONFIG.dP}
            />
          ) : (
            <MockChat
              chatConfig={chatConfig}
              isInputFocused={onInputFocus}
              isInputDisabled={onDisabledInputFocus}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatConfig;
