import MiniChart from "../../components/Charts/MiniChart";
import BarChart from "../../components/Charts/BarChart";
import MessageBalanceWarning from "../../components/MessageBalanceWarning";

const chartData1 = [130, 145, 285, 242, 350, 330, 410];
const chartData2 = [260, 310, 575, 580, 720, 750, 800];
const chartData3 = [210, 240, 510, 500, 680, 690, 735];
const chartData4 = [10, 8, 24, 20, 32, 34, 38];

const BarChart1 = {
  labels: [
    "3+1",
    "2+1",
    "1+1",
    "1+0",
    "Villa",
    "Yatırımlık",
    "Müstakil",
    "Havuz",
    "Otopark",
  ],
  datasets: [
    {
      label: "Kullanıcı İlgisi",
      data: [1200, 1000, 300, 250, 1300, 810, 750, 900, 716],
      backgroundColor: "#00A2E8",
      borderColor: "#0ea5e9",
      tension: 0.4,
      barThickness: 16,
      borderRadius: 12,
    },
  ],
};

const BarChart2 = {
  labels: [
    "Icon Stüdyo Daire",
    "Icon 1+1 Daire",
    "Icon 2+1 Villa",
    "Icon 3+1 Villa",
    "Wox Tip 1 Villa",
    "Wox Tip 2 Villa",
    "Wox Tip 3 Villa",
    "Wox Tip 4 Villa",
  ],
  datasets: [
    {
      label: "biens.ai tarafından önerilme",
      data: [200, 300, 100, 50, 200, 300, 200, 170],
      backgroundColor: "#7dd3fc",
      order: 2,
      tension: 0.4,
      barThickness: 16,
      borderRadius: 12,
    },
    {
      label: "müşteri tıklaması",
      data: [130, 120, 20, 40, 180, 110, 70, 92],
      backgroundColor: "#00A2E8",
      order: 1,
      tension: 0.4,
      barThickness: 16,
    },
  ],
};

const BarChart3 = {
  labels: ["Istanbul", "Muğla", "Ankara", "İzmir", "Bursa", "Kocaeli"],
  datasets: [
    {
      label: "Konum",
      data: [5100, 2000, 1800, 1620, 1000, 250],
      backgroundColor: "#00A2E8",
      barThickness: 18,
      borderRadius: 12,
    },
  ],
};

const BarChart4 = {
  labels: ["50M₺+", "30-50M₺", "15-30M₺", "8-15M₺", "<8M₺"],
  datasets: [
    {
      label: "Bütçe",
      data: [5100, 2000, 300, 1420, 2211, 1398, 20],
      backgroundColor: "#00A2E8",
      barThickness: 18,
      borderRadius: 12,
    },
  ],
};

const Analytics = () => {
  return (
    <div className="lg:container lg:mx-auto px-3 py-4 pt-[calc(50px+1rem)] lg:pt-4">
      <MessageBalanceWarning />
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:mb-5">
        <MiniChart
          title="Sesli İletişim Kurulan Müşteri"
          chartData={chartData4}
          diffCount="+%79"
        />
        <MiniChart
          title="Yazılı İletişim Kurulan Müşteri"
          chartData={chartData1}
          diffCount="+%30"
        />
        <MiniChart
          title="Toplam Gelen Mesaj"
          chartData={chartData2}
          diffCount="+%42"
        />
        <MiniChart
          title="Toplam Gönderilen Mesaj"
          chartData={chartData3}
          diffCount="+%39"
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 my-5">
        <BarChart
          title={"Kelime üretim performansı"}
          data={BarChart1}
          direction="x"
        />
        <BarChart title={"Ürün önerisi"} data={BarChart2} direction="x" />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-5">
        <BarChart
          title={"Ziyaretçi Lokasyonları (tooltip kaldır)"}
          data={BarChart3}
          direction="y"
        />
        <BarChart
          title={"Ziyaretçi Platformları"}
          data={BarChart4}
          direction="y"
        />
      </div>
    </div>
  );
};

export default Analytics;
