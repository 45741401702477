import { useState } from "react";
import ToggleInput from "../../components/ToggleInput";
import { Edit, Delete } from "@mui/icons-material";
import Button from "../../components/Button";
import AIRuleDialog from "../../components/Dialog/AIRule";
import AlertDialog from "../../components/Dialog/Alert";
import {
  deleteAIRule,
  Rule,
  setAutoAssignBiensStatus,
} from "../../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  REQUEST_DELETE_AI_RULE,
  REQUEST_PUT_AUTO_ASSIGN_BIENS,
} from "../../api/requests";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const AISettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { company } = useSelector((state: RootState) => state.auth);
  const [aiRuleDialog, setAiRuleDialog] = useState<{
    status: boolean;
    rule?: Rule;
  }>({
    status: false,
  });
  const [aiDeleteDialog, setAiDeleteDialog] = useState<{
    status: boolean;
    ruleId: string;
  }>({ status: false, ruleId: "" });
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleChangeAutoAssign = (newStatus: boolean) => {
    dispatch(setAutoAssignBiensStatus(newStatus));
    REQUEST_PUT_AUTO_ASSIGN_BIENS(newStatus)
      .then(() => {
        toast.success("Auto assign biens.ai status successfully changed");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.log("error", err);
        toast.error("Error happen when changing auto assign status");
        dispatch(setAutoAssignBiensStatus(!newStatus));
      });
  };

  const handleDeleteRule = () => {
    setDeleteLoading(true);
    REQUEST_DELETE_AI_RULE(aiDeleteDialog.ruleId)
      .then(() => {
        toast.success("AI Rule successfully deleted");
        dispatch(deleteAIRule(aiDeleteDialog.ruleId));
        setAiDeleteDialog({
          status: false,
          ruleId: "",
        });
      })
      .catch((err) => {
        console.log("error", err);
        toast.error("Error happen when changing deleting ai rule");
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  return (
    <div className="container mx-auto">
      <div className="bg-white p-2 border-2 rounded-lg mb-2 flex items-center justify-between">
        <div>
          <h3 className="font-bold text-sm text-gray-700 mb-1">
            {t("auto_assign")}
          </h3>
          <span className="text-gray-500 text-xs">{t("auto_assign_desc")}</span>
        </div>
        <ToggleInput
          checked={company.autoAssignBiens}
          onChangeToggle={handleChangeAutoAssign}
        />
      </div>
      <div className="bg-white p-2 border-2 rounded-lg mb-2 flex flex-col">
        <div className="flex items-center justify-between">
          <div>
            <h3 className="font-bold text-sm text-gray-700 mb-1">
              {t("ai_knowledge")}
            </h3>
            <span className="text-gray-500 text-xs">
              {t("ai_knowledge_desc")}
            </span>
          </div>
          <Button
            title={t("add_rule")}
            type="button"
            variant="filled"
            size="sm"
            onClick={() => setAiRuleDialog({ status: true })}
            className="min-w-[90px] items-center justify-center"
          />
        </div>
        <ul className="mt-2">
          {company.AIRules.map((rule, i) => (
            <li className="p-2 hover:bg-gray-100 flex items-center justify-between">
              <span className="text-gray-700 text-xs ">
                {`${i + 1}. ${rule.rule}`}
              </span>
              <div className="flex">
                <Edit
                  className="text-gray-500 cursor-pointer transition duration-200 hover:transition hover:duration-200 hover:text-gray-700"
                  fontSize="small"
                  onClick={() =>
                    setAiRuleDialog({
                      status: true,
                      rule: {
                        _id: rule._id,
                        rule: rule.rule,
                      },
                    })
                  }
                />
                <Delete
                  className="text-red-500 cursor-pointer ms-2 transition duration-200 hover:transition hover:duration-200 hover:text-red-600"
                  fontSize="small"
                  onClick={() => {
                    setAiDeleteDialog({ status: true, ruleId: rule._id });
                  }}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
      {aiRuleDialog.status ? (
        <AIRuleDialog
          closeDialog={() => setAiRuleDialog({ status: false })}
          rule={aiRuleDialog.rule}
        />
      ) : null}
      {aiDeleteDialog.status ? (
        <AlertDialog
          closeDialog={() => setAiDeleteDialog({ status: false, ruleId: "" })}
          alertText={t("confirm_delete_ai_rule")}
          alertType={"confirm"}
          confirmText={t("confirm")}
          onConfirm={handleDeleteRule}
          isConfirmLoading={deleteLoading}
        />
      ) : null}
    </div>
  );
};

export default AISettings;
