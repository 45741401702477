import Dialog from "./index";
import React, { FormEventHandler, useRef, useState } from "react";
import { Clear } from "@mui/icons-material";
import Button from "../Button";
import useOutsideClick from "../../hooks/useOutsideClick";
import { toast } from "react-toastify";
import { REQUEST_CHANGE_PASSWORD } from "../../api/requests";
import { useTranslation } from "react-i18next";

type Props = {
  closeDialog: () => void;
};
const ChangePasswordDialog: React.FC<Props> = ({ closeDialog }) => {
  const { t } = useTranslation();
  const dialogRef = useRef<HTMLDivElement>(null);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);

  const handleCloseWithAnimation = () => {
    if (changePasswordLoading) {
      return;
    }
    dialogRef.current?.classList.add("animate-scale-down-anim");
    setTimeout(() => {
      closeDialog();
    }, 200);
  };
  useOutsideClick(dialogRef, handleCloseWithAnimation);

  const handleSubmitForm: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (changePasswordLoading) {
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password must be equal");
      return;
    }
    if (newPassword.length < 8 || password.length < 8) {
      toast.error("Password should at least 8 chars");
      return;
    }
    setChangePasswordLoading(true);
    REQUEST_CHANGE_PASSWORD(password, newPassword, confirmPassword)
      .then(() => {
        toast.success("Password updated successfully");
        handleCloseWithAnimation();
      })
      .catch(() => toast.error("Password could not updated"))
      .finally(() => setChangePasswordLoading(false));
  };

  return (
    <Dialog>
      <div
        ref={dialogRef}
        className="bg-white rounded-lg px-4 py-2 min-w-[25vw] animate-scale-anim"
      >
        <button
          className="hover:bg-gray-200 rounded-lg absolute top-4 right-4"
          onClick={handleCloseWithAnimation}
        >
          <Clear className="text-gray-700" />
        </button>
        <form onSubmit={handleSubmitForm} className="mt-5">
          <div className="flex flex-col my-3">
            <label htmlFor={"current-password"} className="text-sm">
              {t("current_password")}
              <b className="text-red-600 ms-1">*</b>
            </label>
            <input
              className="border py-1 px-2 rounded mt-2 outline-0"
              id={"current-password"}
              type={"password"}
              placeholder={"••••••••"}
              required={true}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="flex flex-col my-3">
            <label htmlFor={"new-password"} className="text-sm">
              {t("new_password")}
              <b className="text-red-600 ms-1">*</b>
            </label>
            <input
              className="border py-1 px-2 rounded mt-2 outline-0"
              id={"new-password"}
              type={"password"}
              placeholder={"••••••••"}
              required={true}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="flex flex-col my-3">
            <label htmlFor={"confirm-new-password"} className="text-sm">
              {t("confirm_new_password")}
              <b className="text-red-600 ms-1">*</b>
            </label>
            <input
              className="border py-1 px-2 rounded mt-2 outline-0"
              id={"confirm-new-password"}
              type={"password"}
              placeholder={"••••••••"}
              required={true}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <Button
            title={t("change_password")}
            type="submit"
            size="sm"
            isLoading={changePasswordLoading}
          />
        </form>
      </div>
    </Dialog>
  );
};

export default ChangePasswordDialog;
