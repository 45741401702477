import { Clear } from "@mui/icons-material";
import Dialog from "./index";
import React, { FormEventHandler, useRef, useState } from "react";
import Button from "../Button";
import { Rule, setAIRules, updateAIRule } from "../../store/slices/authSlice";
import { REQUEST_ADD_AI_RULE, REQUEST_PUT_AI_RULE } from "../../api/requests";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

type Props = {
  closeDialog: () => void;
  rule?: Rule;
};
const AIRuleDialog: React.FC<Props> = ({ closeDialog, rule }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ruleState, setRuleState] = useState(rule?.rule || "");
  const dialogRef = useRef<HTMLDivElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCloseWithAnimation = () => {
    dialogRef.current?.classList.add("animate-scale-down-anim");
    setTimeout(() => {
      closeDialog();
    }, 200);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let request;
    if (rule?._id) {
      request = REQUEST_PUT_AI_RULE(rule._id, ruleState);
    } else {
      request = REQUEST_ADD_AI_RULE(ruleState);
    }
    request
      .then((res) => {
        if (rule?._id) {
          dispatch(updateAIRule({ ruleId: rule._id, rule: ruleState }));
        } else if (res.data?.data?.data) {
          dispatch(setAIRules(res.data.data.data));
        }
        handleCloseWithAnimation();
      })
      .catch(() => {
        toast.error("Error happen when adding/updating AI Rule");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Dialog>
      <div
        ref={dialogRef}
        className="bg-white rounded-lg px-4 py-2 min-w-[50vw] animate-scale-anim"
      >
        <div className="flex items-center justify-between border-b-2 pb-2 mb-2">
          <h3 className="font-bold">{`${rule ? t("edit_ai_rule") : t("add_ai_rule")}`}</h3>
          <button
            className="hover:bg-gray-200 rounded-lg"
            onClick={handleCloseWithAnimation}
          >
            <Clear className="text-gray-700" />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <textarea
            rows={4}
            className="w-full text-sm text-gray-900 bg-white border-0 focus:outline-0 min-h-14 max-h-60 border-2 rounded-lg p-2 mb-2"
            placeholder={t("add_rule_placeholder")}
            value={ruleState}
            onChange={(e) => setRuleState(e.target.value)}
            autoFocus
            required
          ></textarea>
          <Button
            type="submit"
            title={rule ? t("update_rule") : t("add_rule")}
            size="sm"
            isLoading={isSubmitting}
          />
        </form>
      </div>
    </Dialog>
  );
};

export default AIRuleDialog;
