import React, { useEffect } from "react";
import Navigation from "./Navigation";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import setupAxios from "./api/setupAxios";
import moment from "moment";
import "moment/locale/tr";
import "moment/locale/en-gb";

setupAxios(store);
function App() {
  useEffect(() => {
    const userLanguage = navigator.language || "en";
    moment.locale(userLanguage);
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Navigation />
      </PersistGate>
    </Provider>
  );
}

export default App;
