import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export type Team = {
  _id: string;
  email: string;
  name: string;
  permission: "admin" | "executive" | "agent";
  photoKey?: string;
  photoUrl?: string;
};

interface TeamState {
  team: Team[];
  isLoading: boolean;
}

type AddMemberAction = {
  _id: Team["_id"];
  name: Team["name"];
  email: Team["email"];
  permission: Team["permission"];
};

type EditMemberAction = {
  _id: Team["_id"];
  name: Team["name"];
  permission: Team["permission"];
};

const initialState: TeamState = {
  team: [],
  isLoading: true,
};

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setTeam: (state, action: PayloadAction<Team[]>) => {
      state.team = action.payload;
      state.isLoading = false;
    },
    removeMember: (state, action: PayloadAction<string>) => {
      state.team = [...state.team].filter(
        (item) => item._id !== action.payload,
      );
    },
    addMember: (state, action: PayloadAction<AddMemberAction>) => {
      const newTeams = [...state.team];
      newTeams.push({
        _id: action.payload._id,
        name: action.payload.name,
        email: action.payload.email,
        permission: action.payload.permission,
      });
      state.team = newTeams;
    },
    editMember: (state, action: PayloadAction<EditMemberAction>) => {
      const newTeams = [...state.team];
      const foundMember = newTeams.find(
        (item) => item._id === action.payload._id,
      );
      if (foundMember) {
        foundMember.name = action.payload.name;
        foundMember.permission = action.payload.permission;
        state.team = newTeams;
      }
    },
  },
});

export const { setTeam, removeMember, addMember, editMember } =
  teamSlice.actions;

export default teamSlice.reducer;
