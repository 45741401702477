import React, { useMemo, useState } from "react";

type Props = {
  name: string;
  photoUrl?: string;
  size?: number;
};

const ProfilePhoto: React.FC<Props> = ({ photoUrl, name, size = 36 }) => {
  if (photoUrl) {
    const [isPhotoLoading, setIsPhotoLoading] = useState(true);
    useMemo(() => {
      setIsPhotoLoading(true);
    }, [photoUrl]);
    return (
      <div
        className="relative me-2"
        style={{
          height: size,
          width: size,
          minHeight: size,
          minWidth: size,
        }}
      >
        <img
          src={photoUrl}
          alt={name}
          className="rounded-full object-cover"
          onLoad={() => setIsPhotoLoading(false)}
          style={{
            height: size,
            width: size,
            minHeight: size,
            minWidth: size,
            opacity: isPhotoLoading ? 0 : 1,
          }}
        />
        {isPhotoLoading ? (
          <div
            className="bg-blue-50 absolute top-0 left-0 rounded-full animate-pulse"
            style={{
              height: size,
              width: size,
              minHeight: size,
              minWidth: size,
            }}
          />
        ) : null}
      </div>
    );
  } else {
    const splitName = name.split("#");
    return (
      <div
        className={
          "flex items-center justify-center rounded-full me-2 bg-sky-700 text-white font-bold"
        }
        style={{
          height: size,
          width: size,
          minHeight: size,
          minWidth: size,
          fontSize: size / 3,
        }}
      >
        {splitName[0][0]}
        {splitName?.[1]?.slice(0, 2).toUpperCase()}
      </div>
    );
  }
};

export default ProfilePhoto;
