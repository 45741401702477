import React, { useState } from "react";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import ProfilePhoto from "../../components/ProfilePhoto";
import { removeMember, Team } from "../../store/slices/teamSlice";
import { REQUEST_REMOVE_TEAM_MEMBER } from "../../api/requests";
import { toast } from "react-toastify";
import NewMemberDialog from "../../components/Dialog/NewMemberDialog";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Delete, Edit } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import AlertDialog from "../../components/Dialog/Alert";
import { useTranslation } from "react-i18next";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export type MemberRow = {
  id: string;
  email: string;
  name: string;
  permission: Team["permission"];
};

const TeamPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { team } = useSelector((state: RootState) => state.team);
  const { company } = useSelector((state: RootState) => state.auth);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [addMemberDialog, setAddMemberDialog] = useState<{
    status: boolean;
    row?: MemberRow;
  }>({ status: false });
  const [removeMemberDialog, setRemoveMemberDialog] = useState({
    status: false,
    title: "",
    rowId: "",
  });

  const handleRemoveTeamMember = (memberId: string) => {
    if (!memberId) {
      return;
    }
    setRemoveLoading(true);
    REQUEST_REMOVE_TEAM_MEMBER(memberId)
      .then(() => {
        setRemoveLoading(false);
        dispatch(removeMember(memberId));
        toast.success("Team member removed");
      })
      .catch((err) => {
        console.log("error happen when removing team member", err);
        toast.error("Team member could not removed");
      });
  };

  const rows = team
    .filter((item) => item._id !== company.userId)
    .map((member) => ({
      id: member._id,
      email: member.email,
      name: member.name,
      permission: member.permission,
      photoUrl: member.photoUrl,
    }));

  return (
    <div className="container mx-auto h-full lg:px-3 lg:py-4">
      {addMemberDialog.status ? (
        <NewMemberDialog
          closeDialog={() => setAddMemberDialog({ status: false })}
          member={addMemberDialog.row}
        />
      ) : null}
      <div className="bg-white h-full rounded-lg shadow p-4">
        <div className="flex items-center justify-between mb-2 pt-[60px] px-2 lg:pt-0 lg:px-0 mb-5">
          <h6 className="font-bold text-gray-700">{t("my_team")}</h6>
          <Button
            title={t("add_member")}
            type="button"
            size="sm"
            onClick={() => setAddMemberDialog({ status: true })}
          />
        </div>
        {removeMemberDialog.status ? (
          <AlertDialog
            closeDialog={() =>
              setRemoveMemberDialog({ status: false, title: "", rowId: "" })
            }
            alertText={removeMemberDialog.title}
            alertType="confirm"
            confirmText={t("confirm")}
            isConfirmLoading={removeLoading}
            onConfirm={() => handleRemoveTeamMember(removeMemberDialog.rowId)}
          />
        ) : null}
        <div className="flex w-full h-dvh lg:h-max">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="left" component="th">
                    {t("name")}
                  </TableCell>
                  <TableCell align="left">{t("email")}</TableCell>
                  <TableCell align="left">{t("permissions")}</TableCell>
                  <TableCell align="center" component="th">
                    {t("actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <StyledTableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell scope="row" width={48}>
                      <ProfilePhoto
                        name={row.name}
                        photoUrl={row.photoUrl}
                        size={36}
                      />
                    </TableCell>
                    <TableCell scope="row">{row.name}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.permission}</TableCell>
                    <TableCell align="center">
                      <div className="flex items-center justify-center">
                        <button
                          onClick={() =>
                            setAddMemberDialog({ status: true, row })
                          }
                          className="flex items-center justify-center h-100 w-100 transition hover:bg-gray-200 p-2 rounded"
                        >
                          <Edit className="text-gray-600" />
                        </button>

                        <button
                          disabled={removeLoading}
                          onClick={() =>
                            setRemoveMemberDialog({
                              status: true,
                              title: t("member_delete_confirm", {
                                name: row.name,
                              }),
                              rowId: row.id,
                            })
                          }
                          className="flex items-center justify-center h-100 w-100 ms-2 transition hover:bg-gray-200 p-2 rounded"
                        >
                          {removeLoading ? (
                            <CircularProgress color={"error"} size={18} />
                          ) : (
                            <Delete className="text-red-600" />
                          )}
                        </button>
                      </div>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default TeamPage;
