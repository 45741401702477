import React, { useState, memo } from "react";
import { Clear } from "@mui/icons-material";
import Button from "../Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MessageBalanceWarning = () => {
  const { t } = useTranslation();
  const [display, setDisplay] = useState(true);
  if (!display) {
    return null;
  }

  return (
    <div className="mb-5">
      <div className="rounded-lg bg-red-50 py-5 px-2 border-2 border-dashed border-red-600 relative">
        <div className="absolute top-1 right-2 cursor-pointer hover:bg-red-200 rounded-lg pb-1 px-1">
          <Clear className="text-red-800" onClick={() => setDisplay(false)} />
        </div>
        <div className="ps-2">
          <h4 className="font-bold text-red-800">{t("low_balance_warning")}</h4>
          <span className="text-red-800">{t("low_balance_warning_desc")}</span>
          <Link to={"/dashboard/settings/subscription"}>
            <Button
              title={t("message_package_button")}
              type={"button"}
              className="mt-2"
              size="sm"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default memo(MessageBalanceWarning);
