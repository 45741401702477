import Slider from "rc-slider";
import "rc-slider/assets/index.css";
const SliderInput = ({
  min,
  max,
  value,
  setValue,
  step,
}: {
  min: number;
  max: number;
  value: number;
  setValue: (newValue: number) => void;
  step: number;
}) => (
  <Slider
    min={min}
    max={max}
    step={step}
    value={value}
    onChange={(e) => {
      if (!Array.isArray(e)) {
        setValue(e);
      }
    }}
    styles={{
      handle: {
        background: "#00a2e8",
        borderColor: "#00a2e8",
        opacity: 1,
        height: 14,
        width: 14,
        marginTop: -3,
      },
      track: { background: "#00a2e8", height: 8 },
      rail: { background: "#CCECFA", height: 8 },
    }}
  />
);

export default SliderInput;
